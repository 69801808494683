import React from "react"

import SEO from "../components/SEO"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Overview from "../components/Overview"
import MarkdownContent from "../components/MarkdownContent"

export default function Content({ data, pageContext, location }) {
  return (
    <Layout>
      <SEO
        title={data.mdx.frontmatter.title}
        description={data.mdx.frontmatter.description}
        pathname={location.pathname}
      />
      <Overview context={pageContext} items={data.allStoreListing.edges}>
        <MarkdownContent mdx={data.mdx} />
      </Overview>
    </Layout>
  )
}

export const pageQuery = graphql`
  query Content(
    $id: String
    $etsy_section: String
    $listing_required: Boolean!
    $skip: Int
    $limit: Int
  ) {
    site {
      siteMetadata {
        siteUrl
        title
      }
    }
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        description
      }
    }
    allStoreListing(
      filter: { section: { eq: $etsy_section } }
      skip: $skip
      limit: $limit
    ) @include(if: $listing_required) {
      edges {
        node {
          id
          title
          fields {
            slug
          }
          images {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    }
  }
`
